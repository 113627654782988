.alert {
    position: fixed; /* Fixed position to stay on top */
    bottom: 0; /* Position it at the top of the viewport */
    left: 0; /* Align it to the left edge of the viewport */
    width: 100%; /* Full width of the viewport */
    z-index: 9999; /* Ensure it is on top of other elements */
    margin: 0; /* Remove default margin */
    padding: 15px; /* Adjust padding for appearance */
    box-sizing: border-box; /* Ensure padding is included in the width */
    border-radius: 0; /* No border-radius if you want it full-width with sharp edges */
    text-align: center; /* Center the text within the alert */
    transition: opacity 0.3s ease; /* Smooth transition for appearance/disappearance */
}
